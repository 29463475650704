import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';

import {
  useMmpStatus,
  MmpStatusProvider,
  getReadableProductName,
  ProductProvider,
  useProduct,
} from '#/stores/mmp-status-store';
import { useEffectOnce } from '#/utils/useEffectOnce';
import LoadingIndicator from '#/components/loading-indicator/loading-indicator';
import PageSubTitle from '#/components/page-sub-title/page-sub-title';
import ParadigmInput from '#/material/input';

import MmpWarning from './components/mmp-warning';
import SettingsGrfqMdrfq from './components/settings-grfq-mdrfq';
import SettingsFs from './components/settings-fs';

export default function DerivativesSettings() {
  return (
    <ProductProvider>
      <MmpStatusProvider>
        <DerivativesSettingsContent />
      </MmpStatusProvider>
    </ProductProvider>
  );
}

function DerivativesSettingsContent() {
  const { mmpStatus, load, status, error } = useMmpStatus();
  const [product, setProduct] = useProduct();
  const isMmpTriggered = mmpStatus[product].rate_limit_hit === true;

  useEffectOnce(load);

  if (status === 'error') {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  if (status === 'fetching') {
    return <Loading />;
  }

  return (
    <div data-testid="derivatives-settings">
      <Block>
        <Value css="align-items: center">
          <Typography color="textSecondary" css="width: 170px">
            Product
          </Typography>
          <ParadigmInput
            select
            css="width: 280px"
            margin="none"
            value={product}
            onChange={(event) => setProduct(event.target.value)}
          >
            {Object.keys(mmpStatus).map((product) => (
              <MenuItem value={product} key={product}>
                {getReadableProductName(product)}
              </MenuItem>
            ))}
          </ParadigmInput>
        </Value>
      </Block>

      <Block css="width: 460px">
        <Header>
          <PageSubTitle text="MARKET MAKER PROTECTION" />
          <Typography color="textSecondary">Active</Typography>
        </Header>
        <Typography color="textSecondary">
          MMP is to protect makers during volatile markets by canceling all
          active quotes subject to below settings.
        </Typography>
        {isMmpTriggered && <MmpWarning />}
      </Block>

      <Block>
        <Header>
          <PageSubTitle text="SETTINGS" />
        </Header>
        {renderSettings(product)}
      </Block>
    </div>
  );
}

function renderSettings(product) {
  switch (product) {
    case 'grfq':
      return <SettingsGrfqMdrfq />;
    case 'fs':
      return <SettingsFs />;
    // no default
  }
}

function Block(props) {
  return (
    <>
      <div {...props} />
      <Divider css="margin-top: 30px; margin-bottom: 30px;" />
    </>
  );
}

const Value = styled.div`
  display: grid;
  grid-template-columns: 170px auto;
`;

const Header = styled.div`
  height: 46px;
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 16px;
  align-items: center;
`;

const ErrorMessage = styled.p`
  color: red;
  margin: 3rem;
  text-align: center;
`;

const Loading = styled.div.attrs({
  children: <LoadingIndicator />,
})`
  display: flex;
  justify-content: center;
`;
